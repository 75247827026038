import request from '@/utils/request'
import qs from 'qs';

// 获取app广告位内容
//这里固定的是pid为16的广告，现在设置了两个，一个是宣传用的一个是二维码区域
export function getAppAdInfo() {
  return request({
    url: '/advert/getByPid',
    method: 'post',
    data: qs.stringify({ pid: 16 }),
  })
}