<template>
  <div>
    <!-- NavBar -->
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <!-- ToolBox -->
    <tool-box></tool-box>
    <div class="center-container">
      <img :src="ads1.thumb"/>
    </div>
    <div class="container py-1">
      <el-row>
        <el-row>
          <el-col :span="12">
            <div class="app-ad-block">
              <div class="icon">
                <svg-icon icon-class="studyFinish"></svg-icon>
              </div>
              <div class="content">
                <div class="title">
                  随时随地 想学就学
                </div>
                <div class="description">
                  在线看视频课程/直播课程，免费海量试题练习，碎片化高效率时间利用。支持免费试听、离线观看、节省流量。
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-ad-block">
              <div class="icon">
                <svg-icon icon-class="kequnfugai"></svg-icon>
              </div>
              <div class="content">
                <div class="title">
                  多类考试 全面覆盖
                </div>
                <div class="description">
                  已经涵盖建筑工程、财会经济、金融考试、医药卫生、资格考试、学历教育、中小学同步课程等丰富课程体系。
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-ad-block">
              <div class="icon">
                <svg-icon icon-class="fabujishishuaibeifen"></svg-icon>
              </div>
              <div class="content">
                <div class="title">
                  及时更新 无缝对接
                </div>
                <div class="description">
                  做题记录、错题集，移动学习更方便，移动端（App、小程序）与PC端同步更新，学习时间、进度随时把控。
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-ad-block">
              <div class="icon">
                <svg-icon icon-class="tuisong"></svg-icon>
              </div>
              <div class="content">
                <div class="title">
                  考试资讯 实时推送
                </div>
                <div class="description">
                  覆盖所有主流考试，每天实时更新最新政策、考试资讯、预测题库、成绩查分、证书领取、报考资讯全掌握。
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-row>
    </div>
    <!-- footer -->
    <web-footer></web-footer>
  </div>
  
</template>

<script type="text/javascript">
import NavBar from '@/components/page/NavBar'
import WebFooter from '@/components/page/WebFooter'
import ToolBox from '@/components/toolbox'
import SvgIcon from '@/components/svgicon'
import {getAppAdInfo} from '@/api/app'

export default {
  data() {
    return {
      ads1:{thumb:''},
      ads2:{thumb:''},
      mobileDomain:'',
    }
  },
  components: {
    NavBar,
    WebFooter,
    ToolBox,
    //SvgIcon
  },
  props:{

  },
  created(){
    this.findAppAdInfo()
  },
  methods:{
    findAppAdInfo(){
      getAppAdInfo().then(
        res => {
          if(res.code == 1){
            this.ads1 = res.data[0]
            this.ads2 = res.data[1]
          }
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
  .center-container{
    width:100%;
    max-width:1920px;
    margin:0px auto;
  }
  .app-ad-block{
    display: flex;
    flex-direction: row;
    height:140px;
    width:600px;
    .icon{
      width:100px;
      height:200px;
      font-size:64px;
      vertical-align:middle;
      text-align: center;
      line-height: 140px;
    }
    .content{
      width:500px;
      height:200px;
      padding:20px;;
      .title{
        font-size:18px;
        line-height:40px;
        font-weight: bold;
      }
      .description{
        font-size:16px;
        color:#666;
        line-height:30px;
      }
    }
  }
</style>